import Grid2 from '@mui/material/Grid2';
import Square from './Square';
import { useEffect } from 'react';

function sendData (gameId, playerId, board, playerName, squareTexts, socket){
    try {
        socket.emit('update-board', {gameId, playerId, board, playerName, squareTexts});
    } catch(error){ }
}

function Board({squareTexts, playerId, gameId, playerName, errorDisplay, selectedSquares, setSelectedSquares, setRejectedSquares, socket}) {
    useEffect(() => {
        if(!errorDisplay){
            sendData(gameId, playerId, selectedSquares, playerName, squareTexts, socket);
        }
    }, [gameId, playerId, selectedSquares, playerName, errorDisplay, squareTexts, socket]);
        
    const handleSquareClicked = function(index) {
        if(errorDisplay || index === 12){
            return;
        }
        const newSelectedSquares = selectedSquares.slice();
        if(newSelectedSquares[index]){
            socket.emit("square-clicked", {gameId, message: playerName + " unselected: " + squareTexts[index]});
        } else {
            socket.emit("square-clicked", {gameId, message: playerName + " selected: " + squareTexts[index]});
        }
        newSelectedSquares[index] = !newSelectedSquares[index];
        setSelectedSquares(newSelectedSquares);
        setRejectedSquares([]);
    }

    const createLetters = function(){
        return [
            <Grid2 size={1} key={"B"} style={{marginBottom:"-20px", marginTop:"-15px"}}>
                <h2>B</h2>
            </Grid2>,
            <Grid2 size={1} key={"I"} style={{marginBottom:"-20px", marginTop:"-15px"}}>
                <h2>I</h2>
            </Grid2>,
            <Grid2 size={1} key={"N"} style={{marginBottom:"-20px", marginTop:"-15px"}}>
                <h2>N</h2>
            </Grid2>,
            <Grid2 size={1} key={"G"} style={{marginBottom:"-20px", marginTop:"-15px"}}>
                <h2>G</h2>
            </Grid2>,
            <Grid2 size={1} key={"O"} style={{marginBottom:"-20px", marginTop:"-15px"}}>
                <h2>O</h2>
            </Grid2>
        ]
    }

    const createSquares = function(){
        const squares = [];
        for(let i = 0; i < 25; i++){
            squares.push(
                <Grid2 size={1} key={i}>
                    <Square text={squareTexts[i]} index={i} clicked={selectedSquares[i]} handleClick={handleSquareClicked} preventHover={errorDisplay}></Square>
                </Grid2>
            )
        }
        return squares;
    }
    
    return (
        <Grid2 size={{ xs: 12, sm: 12, md: 7 }}  sx={{containerType: "inline-size"}}>
            <div className="Board">
                <Grid2 container sx={{containerType: "inline-size"}} spacing={".5vw"} columns={5} >
                    {(()=> {
                        if(!errorDisplay){
                            return createLetters()
                        }
                    })()}
                    {createSquares()}
                </Grid2>
            </div>
        </Grid2>
    );
}

export default Board;
