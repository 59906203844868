
import { useState, useRef, Fragment, useEffect } from 'react';
import Button from '@mui/material/Button'
import { Chip, Dialog } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CallEnd from '@mui/icons-material/CallEnd';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled'
import Restore from '@mui/icons-material/Restore'
import Add from '@mui/icons-material/Add'
import { useTheme } from '@mui/material/styles';

const defaultSquares = [`Awkward silence`, `"Sorry, I was muted"`, `Breathing noises`, `Terrible mic quality`, 
    `Kid or pet in background`, `"Can you see my screen?"`, `"Can you guys hear me?"`, `"You're not sharing your screen"`,
    `People interrupt each other`, `"Let's take that offline"`, `"Say again? You cut out"`,
    `Someone looking at their phone`, `"Let's get started"`, `"I'll give a few minutes for people to hop in"`,
    `Technical difficulties`, `"We are over time"`, `Someone not in their office`, `"Happy __day!`,
    `Terribly timed software update`, `Excessive personal chatter`, 
    `"BRB" in the chat`, `"Thanks, all"`, `Could have been an email`, `Wrong screen shared`, `Off-topic tangent`,
    `"Sorry, go ahead"`, `Forgets they're unmuted`, `"You're on mute"`, `"I have to drop"`, `"Can we record?"`
].sort((a, b) => a.replace(`"`,``).localeCompare(b.replace(`"`,``)));

const hybridMeetingSquares = [`Remote person left out`, `"We're getting the room set up"`, `Ears destroyed by feedback`, `"Can the people online hear alright?"`,
    `Online people can't hear`, `Online people can't see`
].sort((a, b) => a.replace(`"`,``).localeCompare(b.replace(`"`,``)));

const corporateSquares = [`"Touch base"`, `"Action Items"`, `"Alignment"`, `"Tech debt"`, `"Let's table that discussion"`, `"Deep dive"`, `"Circle back"`,
    `"Follow up"`, `"Minimum viable product"`, `"Stakeholder"`, `"Best practices"`, `"Agile"`, `"Low-hanging fruit"`, `"Drill down"`, `"Bandwidth"`, `"Synergy"`, `"Next steps"`,
    `"That's a great callout"`, `"Refinement"`, `"We can give a few minutes back"`, `"Regular cadence"`
].sort((a, b) => a.replace(`"`,``).localeCompare(b.replace(`"`,``)));

function SettingsMenu({visible, setVisible, setPossibleSquares, clickCreateNew}) {
    const theme = useTheme();
    let defaultState = null;

    const resetDefaults = function(){
        return defaultSquares.map(square => {return ({"selected": true, "value": square, "id": uuidv4(), "type": "default"})});
    }

    const resetHybrids = function(){
        return hybridMeetingSquares.map(square => {return ({"selected": false, "value": square, "id": uuidv4(), "type": "hybrid"})});
    }

    const resetCorporate = function(){
        return corporateSquares.map(square => {return ({"selected": false, "value": square, "id": uuidv4(), "type": "corporate"})});
    }

    try {
        const storedSettings = JSON.parse(localStorage.getItem("gameSettings"));
        if(storedSettings && storedSettings.version === "1.0.1"){
            defaultState = storedSettings.defaultInputs;
        } else {
            defaultState = resetDefaults().concat(resetHybrids()).concat(resetCorporate());
        }
    } catch(_){
        localStorage.removeItem("gameSettings");
        defaultState = resetDefaults().concat(resetHybrids()).concat(resetCorporate());
    }

    const [defaultInputs, setDefaultInputs] = useState(defaultState.map(x => x));
    const [previousInputs, setPreviousInputs] = useState(JSON.parse(JSON.stringify(defaultState)));
    const [customSquareInput, setCustomSquareInput] = useState("");

    const textInputRef = useRef(null);

    useEffect(() => {
        const possibleSquares = defaultInputs.filter(input=>input.selected).map(input => input.value);
        setPossibleSquares(possibleSquares);
    }, [defaultInputs, setPossibleSquares]);

    const handleDefaultClick = function(id){
        let newDefaultInputs = defaultInputs.map(x => x); 
        const changedIndex = newDefaultInputs.findIndex(input => input.id === id);
        newDefaultInputs[changedIndex].selected = ! newDefaultInputs[changedIndex].selected;
        setDefaultInputs(newDefaultInputs);
    }

    const handleDelete = function(id){
        let newDefaultInputs = defaultInputs.map(x => x); 
        newDefaultInputs = newDefaultInputs.filter(input => input.id !== id);
        setDefaultInputs(newDefaultInputs);
    }

    const handleAddCustomSquare = function(){
        const newDefaultInputs = defaultInputs.map(x => x);
        newDefaultInputs.push({"selected": true, "value": customSquareInput, "id": uuidv4(), "type": "custom"})
        setDefaultInputs(newDefaultInputs);
        setCustomSquareInput("");
        textInputRef.current.value = "";
    }

    const handleTextInputChanged = function(event){
        setCustomSquareInput(event.target.value);
    }

    const handleEnterPressed = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if(customSquareInput.length > 0){
                handleAddCustomSquare();
            }
        }
    }

    const createChips = function(inputArray, clickHandler, deletable){
        const chips = [];
        for(const input of inputArray){
            const backgroundColor = input.selected ? theme.palette.primary.main : "transparent";
            const variant = input.selected ? "filled" : "outlined";
            const onDelete = deletable ? ()=>{handleDelete(input.id)} : undefined;
            chips.push(
                <Chip label={input.value} data-value={input.value} key={input.id} variant={variant} onDelete={onDelete} onClick={()=>{clickHandler(input.id)}} style={{margin: "3px", color: theme.palette.text.primary, backgroundColor, fontSize:"min(3.3vw, 15px)"}}></Chip>
            );
        }
        return chips;
    }

    const handleSave = function(){
        setCustomSquareInput("");
        setPreviousInputs(JSON.parse(JSON.stringify(defaultInputs)));
        textInputRef.current.value = "";
        clickCreateNew();
        try {
            localStorage.setItem("gameSettings", JSON.stringify({version: "1.0.1", defaultInputs}))
        } catch(error){}
    }

    const handleDiscard = function(){
        if(previousInputs){
            setDefaultInputs(JSON.parse(JSON.stringify(previousInputs)));
            setPossibleSquares(previousInputs.filter(input=>input.selected).map(input => input.value));
        }
        setVisible(false);
        setCustomSquareInput("");
    }

    const handleReset = function(){
        setDefaultInputs(resetDefaults().concat(resetHybrids()).concat(resetCorporate()));
    }

    const addButtonBackgroundColor = customSquareInput.length === 0 ? "transparent" : theme.palette.primary.main;
    const count = defaultInputs.filter(input => input.selected).length;
    
    const countDisplay = function(){
        return (
            <>{count} / 24</>
        )
    }

    return (
        <Fragment>
            <Dialog
                open={visible}
                scroll="paper"
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: theme.palette.background.default,
                        textAlign: "center",
                        color: theme.palette.text.primary,
                        border: '2px solid',
                        borderColor: theme.palette.divider,
                        width: '1000px',
                        maxWidth: '90%',
                    },
                }}
                > 
                    <DialogContent>
                        <DialogTitle id="modal-modal-title" variant="h4" component="h2">
                            Customize your game
                        </DialogTitle>
                        Every player who joins your game will be randomly assigned 24 of the options you select here.
                        <h3>Default options</h3>
                        {createChips(defaultInputs.filter(input => input.type === "default"), handleDefaultClick, false)}
                        <h3>Hybrid meeting options</h3>
                        {createChips(defaultInputs.filter(input => input.type === "hybrid"), handleDefaultClick, false)}
                        <h3>Corporate jargon</h3>
                        {createChips(defaultInputs.filter(input => input.type === "corporate"), handleDefaultClick, false)}
                        <h3>Custom squares</h3>
                        {createChips(defaultInputs.filter(input => input.type === "custom"), handleDefaultClick, true)}
                        <br></br>
                        <br></br>
                        <input type="text" maxLength={50} ref={textInputRef} placeholder="Enter custom square" onKeyDown={handleEnterPressed} onChange={handleTextInputChanged} style={{border:"2px solid", borderColor: theme.palette.divider, borderRadius:"5px", fontSize:"22.5px", fontFamily:"monospace", width: "400px", maxWidth:"98%"}}></input>
                        <Button disabled={customSquareInput.length === 0} onClick={handleAddCustomSquare} style={{color: theme.palette.text.primary, backgroundColor: addButtonBackgroundColor, marginTop:"7px", marginBottom:"15px", marginLeft:"5px"}}>
                            Add
                            <Add style={{ fontSize: "20px", margin: "0", marginLeft: "5px" }}></Add>
                        </Button>
                        <div>
                            <Button onClick={handleDiscard}  style={{color: theme.palette.text.contrast, backgroundColor: theme.palette.error.main, paddingLeft:"45px", paddingRight:"42px", margin: "10px", marginTop: "5px", marginBottom: "5px"}}>
                                <CallEnd style={{ fontSize: "20px", margin: "0", marginRight: "10px" }}></CallEnd>
                                CANCEL
                            </Button>
                            <Button onClick={handleReset}  style={{color: theme.palette.text.contrast, backgroundColor: theme.palette.info.main, paddingLeft:"10px", paddingRight:"10px", margin: "10px", marginTop: "5px", marginBottom: "5px"}}>
                                <Restore style={{ fontSize: "20px", margin: "0", marginRight: "10px" }}></Restore>
                                RESET DEFAULTS
                            </Button>
                            {
                                (() => {
                                    if(count < 24){
                                        return "At least 24 options must be selected"
                                    } else {
                                        return (<Button onClick={handleSave} disabled={count < 24} style={{color: theme.palette.text.contrast, backgroundColor: theme.palette.success.main, paddingLeft:"10px", paddingRight:"10px", margin: "10px", marginTop: "5px", marginBottom: "5px"}}>
                                            <PlayCircleFilled style={{ fontSize: "20px", margin: "0", marginRight: "10px" }}></PlayCircleFilled>
                                            SAVE AND BEGIN
                                        </Button>);
                                    }
                                })()
                            }
                        </div>
                        {countDisplay()}
                    </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default SettingsMenu;
