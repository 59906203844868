
import Button from '@mui/material/Button'
import axios from "axios"
import { useEffect, useState } from 'react';
import Grid2 from '@mui/material/Grid2';
import { BASE_URL } from './config';
import ConnectionLost from './ConnectionLost';
import SettingsMenu from './SettingsMenu';
import { useTheme } from '@mui/material/styles';
import ColorThemeButton from "./ColorThemeButton.js";
import Tooltip from '@mui/material/Tooltip';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
}

function Homepage({setGameId, setPlayerName, setBoardLayout, setPlayerId, playerName, setRejoinData, handleThemeButtonClicked}) {
  const theme = useTheme();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramRoomId = params.get("room-id");

  const [buttonsDisabled, setButtonsDisabled] = useState(playerName.length === 0);
  const [roomCodeInput, setRoomCodeInput] = useState(paramRoomId || "");
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [connectionLost, setConnectionLost] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [possibleSquares, setPossibleSquares] = useState([]);

  useEffect(()=> {
    try {
      const storedName = JSON.parse(localStorage.getItem("playerName"));
      if(storedName){
        setPlayerName(storedName.playerName);
        if(storedName.playerName.length > 0){
          setButtonsDisabled(false);
        }
      }
    } catch(error){}
  }, [setPlayerName, setButtonsDisabled]);

  const savePlayerName = function(){
    try {
      localStorage.setItem("playerName", JSON.stringify({playerName}))
    } catch(error){}
  }

  const clickCreateNew = async ()=>{
    try {
      const response = await axios.post(BASE_URL + "/api/new-game", {possibleSquares, playerName});
      shuffleArray(possibleSquares);
      let squares = possibleSquares.slice(0, 12);
      squares.push("Free Space");
      squares = squares.concat(possibleSquares.slice(12, 24));
      setBoardLayout(squares);
      setPlayerId(response.data.playerId)
      setGameId(response.data.gameId);
      const { origin, pathname } = window.location;
      const newUrl = `${origin}${pathname}?room-id=${response.data.gameId}`;
      window.history.replaceState(null, '', newUrl);
      savePlayerName();
    } catch(error){
      console.log(error);
      setConnectionLost(true);
    }
  }
  
  const clickJoinRoom = async ()=>{
    try {
      const response = await axios.get(BASE_URL + "/api/new-player/" + roomCodeInput + "/" + playerName);
      if(response.data.newPlayer){
        const gameSquares = response.data.possibleSquares;
        shuffleArray(gameSquares);
        let squares = gameSquares.slice(0, 12);
        squares.push("Free Space");
        squares = squares.concat(gameSquares.slice(12, 24));
        setBoardLayout(squares);
        setPlayerId(response.data.playerId)
      } else {
        setBoardLayout(response.data.squareTexts);
        setPlayerId(response.data.playerId);
        setRejoinData(response.data)
      }
      
      const { origin, pathname } = window.location;
      const newUrl = `${origin}${pathname}?room-id=${roomCodeInput}`;
      window.history.replaceState(null, '', newUrl);
      setGameId(roomCodeInput);
      savePlayerName();
    } catch(error){
      if(error.status === 400){
        setErrorDisplayed(true);
      } else {
        setConnectionLost(true);
      }
    }
  }

  const handleChangeNameBox = function (event){
    setPlayerName(event.target.value);
    setButtonsDisabled(event.target.value.length === 0);
  }

  const handleChangeRoomCodeBox = function(event){
    setRoomCodeInput(event.target.value.toLowerCase());
    setErrorDisplayed(false);
  }

  if(connectionLost){
    return <ConnectionLost></ConnectionLost>
  }

  const rules = <div style={{marginTop:"75px", width:"80%", maxWidth:"500px", display:"block", marginLeft:"auto", marginRight:"auto", textAlign:"left"}}>
    <h2 style={{textAlign:"center"}}>The Rules:</h2>
    <p>1) The game begins as soon as you join the call.</p>
    <p>2) Any squares that include quotation marks indicate a phrase that someone must say out loud. The phrasing does not need to match exactly what is written, as long as it is close.</p>
    <p>3) You may not mark any squares for things that you do or say yourself or intentionally team up with another player.</p>
    <p>4) You may not "fish" for other people to do or say things that would allow you to mark a square.</p>
    <p>5) When a player reaches BINGO, all other players will have a chance to review their card. At least 50% of players must approve for the player to win.</p>
  </div>

  if(buttonsDisabled){
    return (
      <div className="Homepage">
        <Grid2 container spacing={0} columns={11}>
          <Grid2 size={{ xs: 1, lg: 4 }}>
          </Grid2>
          <Grid2 size={{ xs: 9, lg: 3 }}>
            <h1 style={{marginTop:"50px"}}>Remote Meeting BINGO</h1>
            <input type="text" placeholder="Enter nickname" maxLength={20} autoFocus value={playerName} onChange={handleChangeNameBox} style={{border:"solid 2px", borderColor: theme.palette.divider, borderRadius:"5px", fontSize:"30px", fontFamily:"monospace", marginTop:"50px", marginBottom:"75px", width: "275px"}}></input>
          </Grid2>
          <Grid2 size={{ xs: 1, lg: 4 }}>
          </Grid2>
        </Grid2>
        {rules}
        <div style={{textAlign:"right"}}>
          <ColorThemeButton handleThemeClicked={handleThemeButtonClicked}></ColorThemeButton>
        </div>
      </div>
    )
  }

  const handleSettingsClicked = function(){
    setSettingsOpen(true);
  }

  const handleEnterPressed = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      clickJoinRoom();
    }
  }

  const errorDisplay = errorDisplayed ? "inline" : "none";

  return (
    <div className="Homepage">
      
      <Grid2 container spacing={0} columns={11}>
        <Grid2 size={{ xs: 1, lg: 4 }}>
        </Grid2>
        <Grid2 size={{ xs: 9, lg: 3 }}>
          <h1 style={{marginTop:"50px"}}>Remote Meeting BINGO</h1>
          <input type="text" placeholder="Enter nickname" maxLength={20} value={playerName} onChange={handleChangeNameBox} style={{border:"solid 2px", borderColor: theme.palette.divider, borderRadius:"5px", fontSize:"30px", fontFamily:"monospace", marginTop:"50px", marginBottom:"75px", width: "275px"}}></input>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 4 }}>
        </Grid2>

        <Grid2 size={{ xs: 1, lg: 3 }}>
        </Grid2>
        <Grid2 size={{ xs: 9, lg: 2 }} style={{textAlign: "center"}}>
          <Button disabled={buttonsDisabled} onClick={handleSettingsClicked} style={{color: theme.palette.text.primary, backgroundColor: theme.palette.primary.main, marginTop:"35px"}}>Start New Game</Button>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 0}}>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 0}}>
        </Grid2>
        <Grid2 size={{ xs: 9, lg: 1 }}>
          <span style={{fontSize:"35px", marginTop:"26px", marginBottom:"15px", display:"block"}}>OR</span>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 0}}>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 0}}>
        </Grid2>
        <Grid2 size={{ xs: 9, lg: 2 }} style={{textAlign:"center"}}>
          <input type="text" placeholder="Enter room code" maxLength={4} defaultValue={paramRoomId || ""} onChange={handleChangeRoomCodeBox} onKeyDown={handleEnterPressed} style={{border:"2px solid", borderColor: theme.palette.divider, borderRadius:"5px", fontSize:"30px", fontFamily:"monospace", width: "275px"}}></input>
          <span style={{color: theme.palette.text.error, display: errorDisplay}}><br></br>Selected room does not exist<br></br></span>
          <br></br>
          <Button disabled={buttonsDisabled} onClick={clickJoinRoom} style={{color: theme.palette.text.primary, backgroundColor: theme.palette.primary.main, marginTop:"15px"}}>Join Existing Game</Button>
        </Grid2>
        <Grid2 size={{ xs: 1, lg: 3}}>
        </Grid2>
      </Grid2>
      
      <SettingsMenu visible={settingsOpen} setVisible={setSettingsOpen} setPossibleSquares={setPossibleSquares} clickCreateNew={clickCreateNew}></SettingsMenu>
      {rules}
      <div style={{textAlign:"right"}}>
        <ColorThemeButton handleThemeClicked={handleThemeButtonClicked}></ColorThemeButton>
      </div>
    </div>
  );
}

export default Homepage;
