import './App.css';
import { useState } from 'react';
import Homepage from "./Homepage.js"
import Game from './Game.js';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

const darkTheme = createTheme({
  name: "dark",
  palette: {
    background: {
      default: "#282c33",
    },
    primary: {
      main: '#4e57cd',
    },
    secondary: {
      main: '#8083ed',
    },
    error: {
      main: "#c82d4c",
      dark: "#663333",
    },
    success: {
      main: "#008800",
    },
    info: {
      main: '#f5b216',
      dark: '#f5b216',
    },
    divider: '#fff',
    text: {
      primary: '#fff',
      disabled: "#888",
      error: "#f00",
      contrast: "#fff"
    }
  },
});

const lightTheme = createTheme({
  name: "light",
  palette: {
    background: {
      default: "#e3ecff",
    },
    primary: {
      main: '#ffe155',
    },
    secondary: {
      main: '#d89810',
    },
    error: {
      main: "#c82d4c",
      dark: "#663333",
    },
    success: {
      main: "#008800",
    },
    info: {
      main: '#f5b216',
      dark: '#582d03',
    },
    divider: '#000',
    text: {
      primary: '#000',
      disabled: "#888",
      error: "#f00",
      contrast: '#fff'
    }
  },
});

const cherryTheme = createTheme({
  name: "cherry",
  palette: {
    background: {
      default: "#ffe5f2",
    },
    primary: {
      main: '#ff92b9',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: "#c82d4c",
      dark: "#663333",
    },
    success: {
      main: "#008800",
    },
    info: {
      main: '#f5b216',
      dark: '#000',
    },
    divider: '#c196ac',
    text: {
      primary: '#000',
      disabled: "#888",
      error: "#f00",
      contrast: '#fff'
    }
  },
});

const halloweenTheme = createTheme({
  name: "halloween",
  palette: {
    background: {
      default: "#270d2c",
    },
    primary: {
      main: '#F2613F',
    },
    secondary: {
      main: '#481E14',
    },
    error: {
      main: "#c82d4c",
      dark: "#663333",
    },
    success: {
      main: "#008800",
    },
    info: {
      main: '#f5b216',
      dark: '#fff',
    },
    divider: '#9B3922',
    text: {
      primary: '#fff',
      disabled: "#888",
      error: "#f00",
      contrast: '#fff'
    }
  },
});

function App() {
  let defaultTheme = lightTheme;
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    defaultTheme = darkTheme;
  }
  
  const savedTheme = localStorage.getItem("colorTheme");
  if(savedTheme === "light"){
    defaultTheme = lightTheme;
  } else if(savedTheme === "cherry"){
    defaultTheme = cherryTheme;
  } else if(savedTheme === "halloween"){
    defaultTheme = halloweenTheme;
  }

  const [gameId, setGameId] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [playerId, setPlayerId] = useState("");
  const [boardLayout, setBoardLayout] = useState("");
  const [rejoinData, setRejoinData] = useState(null);
  const [theme, setTheme] = useState(defaultTheme)

  document.body.style.backgroundColor = theme.palette.background.default;
  document.body.style.color = theme.palette.text.primary;

  const handleThemeButtonClicked = function(themeName){
    if(themeName === "light"){
      setTheme(lightTheme);
      localStorage.setItem("colorTheme", "light");
    } else if(themeName === "dark") {
      setTheme(darkTheme);
      localStorage.setItem("colorTheme", "dark");
    } else if(themeName === "cherry") {
      setTheme(cherryTheme);
      localStorage.setItem("colorTheme", "cherry");
    } else {
      setTheme(halloweenTheme);
      localStorage.setItem("colorTheme", "halloween");
    }
  }

  if(!gameId){
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
            <Homepage setGameId={setGameId} setPlayerName={setPlayerName} setBoardLayout={setBoardLayout} setPlayerId={setPlayerId} playerName={playerName} setRejoinData={setRejoinData} handleThemeButtonClicked={handleThemeButtonClicked}></Homepage>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{color: theme.palette.text.primary, backgroundColor: theme.palette.background.default}}>
          <Game gameId={gameId} playerName={playerName} playerId={playerId} boardLayout={boardLayout} rejoinData={rejoinData} handleThemeButtonClicked={handleThemeButtonClicked}></Game>
      </div>
    </ThemeProvider>
  );
}

export default App;
