import Grid2 from '@mui/material/Grid2';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';

function MiniBoard({selectedSquares, playerName}) {
    const theme = useTheme();

    const createSquares = function(){
        const squares = [];
        for(let i = 0; i < 25; i++){
            const backgroundColor = selectedSquares[i] ? theme.palette.primary.main : "transparent";
            squares.push(
                <Grid2 size={1} key={uuidv4()}>
                    <div className="MiniSquare" style={{backgroundColor, borderColor: theme.palette.divider}}>
                        
                    </div>
                </Grid2>
            )
        }
        return squares;
    }
    
    return (
        <div className="MiniBoard">
            <span style={{overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", display:"block"}}>{playerName}</span>
            <Grid2 container spacing={".3vmax"} columns={5}>
                {createSquares()}
            </Grid2>
        </div>
    );
}

export default MiniBoard;
