import Board from "./Board";
import Grid2 from '@mui/material/Grid2';
import Panel from "./Panel";
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BASE_URL } from "./config";
import ConnectionLost from "./ConnectionLost";
import EmojiBarrage from "./EmojiBarrage.js";
import VerificationModal from "./VerificationModal.js";
import { io } from "socket.io-client";
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button'

function Game({gameId, playerName, playerId, boardLayout, rejoinData, handleThemeButtonClicked}) {
    const theme = useTheme();
    const [selectedSquares, setSelectedSquares] = useState([false, false, false, false, false, false, false, false, false, false, false, false, true, false, false,
        false, false, false, false, false, false, false, false, false, false]);
    const [rejectedSquares, setRejectedSquares] = useState([]);
    const [boards, setBoards] = useState([]);
    const [winningPlayerName, setWinningPlayerName] = useState("");
    const [potentialWinningPlayer, setPotentialWinningPlayer] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [socket, setSocket] = useState(null);
    const [feed, setFeed] = useState([]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70vw",
        maxWidth: "400px",
        border: '2px solid',
        borderColor: theme.palette.divider,
        borderRadius: "15px",
        boxShadow: 24,
        p: 4,
        backgroundColor: theme.palette.background.default,
        display: "block",
        textAlign: "center"
    };

    useEffect(() => {
        if(rejoinData){
            setSelectedSquares(rejoinData.selectedSquares);
        }
    }, [rejoinData]);

    const handleGameData = function(data, currentSocket){
        if(data.error){
            setDisplayError(true);
            return;
        }

        const winningPlayer = data["winningPlayerName"];
        if (winningPlayer) {
            setWinningPlayerName(prevWinningPlayerName => {
                if (prevWinningPlayerName === "") {
                    setModalOpen(true);
                    return winningPlayer;
                }
                return prevWinningPlayerName;
            });
        }

        const newPotentialWinningPlayer = data["potentialWinningPlayer"];
        setPotentialWinningPlayer((prevPotentialWinningPlayer)=>{
            if((prevPotentialWinningPlayer && !newPotentialWinningPlayer) || (newPotentialWinningPlayer?.statusRejected && !prevPotentialWinningPlayer)){
                return prevPotentialWinningPlayer;
            }
            return newPotentialWinningPlayer;
        });
        if(newPotentialWinningPlayer && newPotentialWinningPlayer.playerId){
            if(newPotentialWinningPlayer.statusRejected && !newPotentialWinningPlayer.rejectionAccepted && newPotentialWinningPlayer.playerId === playerId){
                const newRejectedSquares = [];
                for(const rejection of newPotentialWinningPlayer.rejected){
                    for(const index of rejection.squares){
                        newRejectedSquares.push(index);
                    }
                }
                setRejectedSquares(newRejectedSquares);
                currentSocket.emit("rejection-accepted", {gameId});
            }
        }

        setBoards(data.players)
    }

    useEffect(() => {
        const newSocket = io(BASE_URL);
        setSocket(newSocket);

        newSocket.on('update-state', (data) => {
            handleGameData(data, newSocket);
        });

        return () => {
            newSocket.disconnect();
        };
    }, [])

    useEffect(() => {
        if(socket){
            socket.off('update-feed');
            socket.on('update-feed', (data) => {
                let newFeed = feed.slice();
                newFeed.push(data);
                if(newFeed.length > 100){
                    newFeed = newFeed.slice(newFeed.length - 100, newFeed.length);
                }
                setFeed(newFeed);
            });
        }
    }, [feed, socket])
    
    const handleModalClose = () => {
        setModalOpen(false)
    };

    useEffect(() => {
        const newSelectedSquares = selectedSquares.slice();
        setSelectedSquares((prevSelectedSquares) => {
            let changed = false;
            for(let index of rejectedSquares){
                if(prevSelectedSquares[index]){
                    newSelectedSquares[index] = false;
                    const newRejectedSquares = rejectedSquares.slice();
                    newRejectedSquares.filter(val => val !== index);
                    changed = true;
                }
            }
            return changed ? newSelectedSquares : prevSelectedSquares;
        });
    }, [rejectedSquares, selectedSquares]);

    if(displayError){
        return <ConnectionLost></ConnectionLost>
    }

    return (
        <>
            <Grid2 container spacing={1} columns={12}>
                <Board squareTexts={boardLayout} playerId={playerId} playerName={playerName} gameId={gameId} errorDisplay={false} selectedSquares={selectedSquares} setSelectedSquares={setSelectedSquares} setRejectedSquares={setRejectedSquares} socket={socket}></Board>
                <Panel gameId={gameId} boards={boards} playerId={playerId} winningPlayerName={winningPlayerName} socket={socket} handleThemeButtonClicked={handleThemeButtonClicked} feed={feed} playerCount={Object.keys(boards).length}></Panel>
            </Grid2>
            
            <Modal
                open={modalOpen}
                >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        {winningPlayerName} is the Winner!
                    </Typography>
                    
                    <Button onClick={handleModalClose} style={{color: theme.palette.text.primary, backgroundColor: theme.palette.primary.main, marginTop:"15px", marginBottom:"-10px"}}>Continue</Button>
                </Box>
            </Modal>
            
            
            {(()=>{
                if(modalOpen){
                    return <EmojiBarrage></EmojiBarrage>
                }
            })()}

            <VerificationModal potentialWinningPlayer={potentialWinningPlayer} playerId={playerId} playerName={playerName} boards={boards} gameId={gameId} setPotentialWinningPlayer={setPotentialWinningPlayer} winningPlayerName={winningPlayerName} socket={socket}></VerificationModal>
        </>
    );
}

export default Game;
