import Board from "./Board";
import Button from '@mui/material/Button'
import CallEnd from '@mui/icons-material/CallEnd';
import Grid2 from '@mui/material/Grid2';

function ConnectionLost() {
    const handleQuitClicked = function(){
        const { origin, pathname } = window.location;
        const newUrl = `${origin}${pathname}`;
        window.location.href = newUrl;
    }

    return (
        <>
            <Grid2 container spacing={0} columns={11}>
                <Grid2 size={3}></Grid2>
                <Grid2 size={3}>
                    <h1 style={{marginTop:"25px", marginLeft:"75px"}}>Connection to server lost</h1>
                </Grid2>
                <Grid2 size={2}>
                    <Button onClick={handleQuitClicked} style={{color:"white", backgroundColor:"#c82d4d", paddingLeft:"20px", paddingRight:"20px", marginTop:"30px", marginLeft:"-50px"}}>
                        <CallEnd style={{ fontSize: "20px", margin: "0", marginRight: "10px" }}></CallEnd>
                        Refresh Page
                    </Button>
                </Grid2>
            </Grid2>
            
            
            <Board errorDisplay={true} squareTexts={["?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?","?"]} selectedSquares={[false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false]}></Board>
        </>
    )
}

export default ConnectionLost;
