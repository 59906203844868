
import { Chip, Divider, Grid2, Tooltip } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { useRef, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

function GameFeed({feed, playerCount}){
    const theme = useTheme();
    const scrollRef = useRef(null);
    const [open, setOpen] = useState(true);

    const createFeedRows = function (){
        const feedRows = [];
        for(let i = 0; i < feed.length; i++){
            feedRows.push(
                <div key={i}>
                    <ListItemText primary={feed[i]} ref={i === feed.length - 1 ? scrollRef : undefined}></ListItemText>
                    <Divider variant="middle" style={{display: i === feed.length - 1 ? "none" : "block"}}/>
                </div>
            );
        }
        return feedRows;
    }

    useEffect(() => {
        if (scrollRef.current) {
            const { scrollX, scrollY } = window;
            scrollRef.current.scrollIntoView({ behavior: "instant" });
            window.scrollTo(scrollX, scrollY);
        }
    }, [feed, open]);

    const handleClick = function(){
        setOpen(!open);
    }
    
    if(playerCount === 1 || feed.length === 0){
        return (<></>);
    }

    if(!open){
        return (
            <Chip label="Open Activity Feed" onClick={handleClick} style={{color: theme.palette.text.primary, backgroundColor: theme.palette.primary.main}}></Chip>
        )
    }

    return (
        <>
        <Grid2 container columns={12} style={{ maxWidth: "min(100%, 500px)", marginTop: "2vh", marginLeft: "auto", marginRight: "auto" }}>
            <Grid2 size={{xs: 12, sm: 12, md: 11}}>
                <List sx={{
                    overflow: 'auto',
                    maxHeight: "150px",
                    position: 'relative',
                    textAlign: "center",
                    padding: "5px",
                    "&::-webkit-scrollbar": {
                        width: "8px"
                    },
                    "&::-webkit-scrollbar-track": {
                        background: theme.palette.secondary.main,
                        borderRadius: "2px"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: theme.palette.primary.main,
                        borderRadius: "2px"
                    }
                }}>
                    {createFeedRows()}
                </List>
            </Grid2>
            <Grid2 size={{xs: 12, sm: 12, md: 1}} style={{textAlign: "right"}}>
                <Tooltip title={"Close Activity Feed"}>
                    <CloseIcon style={{color: theme.palette.primary.main, cursor:"pointer"}} onClick={handleClick}></CloseIcon>
                </Tooltip>
            </Grid2>
        </Grid2></>
    );
}

export default GameFeed;