
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

function EmojiBox() {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, Math.random() * 3000);
  
      return () => clearTimeout(timer)
    }, []);
    
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const roll = Math.random();
  let emojiSymbol = roll < .20 ? "👍" : "🎉";
  if (roll > .4){
    emojiSymbol = "👏";
  }
  if(roll > 0.6){
    if(theme.name === "light"){
      emojiSymbol = "💐";
    } else if(theme.name === "dark"){
      emojiSymbol = "⭐";
    } else if(theme.name === "cherry"){
      emojiSymbol = "🍒";
    } else if(theme.name === "halloween"){
      emojiSymbol = "🕸";
    }
  }
  if(roll > 0.8){
    if(theme.name === "light"){
      emojiSymbol = "🌻";
    } else if(theme.name === "dark"){
      emojiSymbol = "🎆";
    } else if(theme.name === "cherry"){
      emojiSymbol = "🌸";
    } else if(theme.name === "halloween"){
      emojiSymbol = "🎃";
    }
  }

  return (
      <span className="floating-emoji" style={{fontSize: "30px", left: getRandomNumber(5, 95) + "%", display: isVisible ? "inline" : "none", zIndex: 1301}}>{emojiSymbol}</span>
  );
}

export default EmojiBox;
