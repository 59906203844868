
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Square from './Square';
import Grid2 from '@mui/material/Grid2';
import Sync from '@mui/icons-material/Sync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DialogContent from '@mui/material/DialogContent';
import { Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function sendData (gameId, playerId, playerName, squares, status, socket){
    try {
        socket.emit('update-verification', {gameId, playerId, playerName, squares, status});
    } catch(error){ }
}

function VerificationModal({potentialWinningPlayer, playerId, playerName, boards, gameId, setPotentialWinningPlayer, winningPlayerName, socket}) {
    const theme = useTheme();
    const [selectedSquares, setSelectedSquares] = useState([false, false, false, false, false]);

    useEffect(() => {
        if(socket){
            socket.off('update-verification');
            socket.on('update-verification', () => {
                setSelectedSquares([false, false, false, false, false]);
            });
        }
    }, [setSelectedSquares, socket]);

    if(!potentialWinningPlayer || !potentialWinningPlayer.playerName || winningPlayerName || !boards[potentialWinningPlayer.playerId]){
        return;
    }
    
    const handleSquareClicked = function(index){
        const newSelectedSquares = selectedSquares.slice();
        newSelectedSquares[index] = !newSelectedSquares[index];
        setSelectedSquares(newSelectedSquares);
    }

    const createSquares = function(){
        const squares = [];
        const squareTexts = boards[potentialWinningPlayer.playerId].squareTexts;
        for(let i = 0; i < 5; i++){
            const preventHover = potentialWinningPlayer.playerId === playerId || 
                potentialWinningPlayer.winningSquares[i] === 12 || 
                potentialWinningPlayer.rejected.find(rejection => rejection.playerId === playerId);
            squares.push(
                <Grid2 size={1} key={i}>
                    <Square text={squareTexts[potentialWinningPlayer.winningSquares[i]]} index={i} clicked={selectedSquares[i]} handleClick={handleSquareClicked} preventHover={preventHover}></Square>
                </Grid2>
            )
        }
        return squares;
    }

    const handleAccept = function(){
        setSelectedSquares([false, false, false, false, false]);
        sendData(gameId, playerId, playerName, [], "accept", socket)
    }
    
    const handleReject = function(){
        const rejectedSquares = [];
        for(let i = 0; i < 5; i++){
            if(selectedSquares[i]){
                rejectedSquares.push(potentialWinningPlayer.winningSquares[i]);
            }
        }
        sendData(gameId, playerId, playerName, rejectedSquares, "reject", socket)
    }

    const canReject = selectedSquares.some(square => square);
    let subtext = playerId === potentialWinningPlayer.playerId ? 
                    <>Your opponents are verifying your card</> : 
                    `If you disagree with any of these squares, select them and click "Reject"`;
    if(potentialWinningPlayer.statusRejected){
        subtext = playerId === potentialWinningPlayer.playerId ? "Your opponents have rejected your card! Try again!" : "Your opponent's card has been rejected!";
    }
    const winText = playerId === potentialWinningPlayer.playerId ? "You" : potentialWinningPlayer.playerName;
    const claimText = playerId === potentialWinningPlayer.playerId ? "You are" : potentialWinningPlayer.playerName + " is";
    const headerText = potentialWinningPlayer.statusRejected ? "BINGO Rejected!" : claimText + " claiming BINGO!";
    
    const createTextList = function (){
        const texts = [];
        for(const approval of potentialWinningPlayer.accepted){
            texts.push(
                <p key={approval.playerId}>Approved by {approval.playerName}<CheckCircleIcon style={{ fontSize: "30px", marginLeft: "5px", verticalAlign: '-8px', color:"#00aa00" }}></CheckCircleIcon></p>
            )
        }
        for(const rejection of potentialWinningPlayer.rejected){
            texts.push(
                <p key={rejection.playerId}>Rejected by {rejection.playerName}<ThumbDownIcon style={{ fontSize: "30px", marginLeft: "5px", verticalAlign: '-8px', color:"red" }}></ThumbDownIcon></p>
            )
        }
        return texts;
    }
    const createRejections = function (){
        const rejections = [];

        for(let i = 0; i < 5; i++){
            let indexRejections = "";
            for(const rejection of potentialWinningPlayer.rejected){
                if(rejection.squares.indexOf(potentialWinningPlayer.winningSquares[i]) > -1){
                    if(indexRejections === ""){
                        indexRejections = rejection.playerName;
                    } else {
                        indexRejections += ", " + rejection.playerName;
                    }
                }
            }
            if(indexRejections !== ""){
                rejections.push(<Grid2 size={1} key={i}><p style={{marginBottom: "0px", marginTop:"0px"}}><ThumbDownIcon style={{ fontSize: "30px", marginRight: "5px", verticalAlign: '-8px', color:"red" }}></ThumbDownIcon>{indexRejections}</p></Grid2>);
            } else {
                rejections.push(<Grid2 size={1} key={i}></Grid2>);
            }
        }

        return rejections;
    }

    const handleContinueClicked = function(){
        setPotentialWinningPlayer(undefined);
        setSelectedSquares([false, false, false, false, false]);
    }

    const responseSent = potentialWinningPlayer.rejected.find(obj => obj.playerId === playerId) || potentialWinningPlayer.accepted.find(obj => obj.playerId === playerId);

    return (
        <>
            <Dialog
                open={true}
                scroll="paper"
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: theme.palette.background.default,
                        textAlign: "center",
                        border: '2px solid',
                        borderColor: theme.palette.divider,
                        color: theme.palette.text.primary,
                        width: '1000px',
                        maxWidth: '95%',
                        containerType: "inline-size"
                    },
                }}
                >
                <DialogContent style={{paddingLeft:"2vw", paddingRight:"2vw"}}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" style={{marginBottom: "25px"}}>
                        {headerText}
                    </Typography>

                    <Grid2 container spacing={"0.75vw"} columns={5} style={{marginBottom: "25px"}}>
                        {createSquares()}
                        {createRejections()}
                    </Grid2>

                    <p>{subtext}</p>
                    {(()=>{
                        if(!potentialWinningPlayer.statusRejected){
                            return (
                                <p>{winText} will win if there are at least 50% approvals when time runs out<Sync className="spinner" style={{ fontSize: "30px", marginLeft: "5px", marginRight: "5px",verticalAlign: '-8px' }}></Sync>{potentialWinningPlayer.timeout}</p>
                            )
                        }
                    })()}
                    

                    {createTextList()}
                    
                    {(()=>{
                        if(!responseSent && playerId !== potentialWinningPlayer.playerId && !potentialWinningPlayer.statusRejected){
                            return (
                                <>
                                    <Button onClick={handleAccept} style={{color: theme.palette.text.contrast, backgroundColor: theme.palette.success.main, marginRight:"15px", marginTop:"15px", marginBottom:"-10px"}}>Approve</Button>
                                    <Button disabled={!canReject} onClick={handleReject} style={{color: theme.palette.text.contrast, backgroundColor: canReject ? theme.palette.error.main : theme.palette.error.dark, marginLeft:"15px", marginTop:"15px", marginBottom:"-10px"}}>Reject</Button>
                                </>
                            )
                        }
                    })()}
                    {(()=>{
                        if(potentialWinningPlayer.statusRejected){
                            return (
                                <Button onClick={handleContinueClicked} style={{backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, marginRight:"15px", marginTop:"15px", marginBottom:"-10px"}}>Continue</Button>
                            )
                        }
                    })()}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default VerificationModal;
