
import Grid2 from '@mui/material/Grid2';
import MiniBoard from './MiniBoard';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { Snackbar, Tooltip } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { BASE_URL } from './config';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button'
import ColorThemeButton from "./ColorThemeButton.js";
import CallEnd from '@mui/icons-material/CallEnd';
import GameFeed from './GameFeed';

function Panel({gameId, boards, playerId, winningPlayerName, socket, handleThemeButtonClicked, feed, playerCount}) {
    const theme = useTheme();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(BASE_URL + "/?room-id=" + gameId)
          .then(() => {
            setSnackbarOpen(true);
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      };
    
      const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };

    const invitePlayers = <div style={{marginTop: Object.keys(boards).length > 1 ? "20px" : "-15px"}}>
                                <h2>Invite Other Players</h2>
                                Step 1: Send them to <b>RemoteMeetingBingo.com</b>
                                <Tooltip title={"Click to copy"}>
                                    <ContentCopyIcon sx={{marginLeft:"5px", verticalAlign: '-6px', cursor:"copy", "&:hover":{color: theme.palette.text.disabled}}} className="content-copy" onClick={handleCopy}>
                                    </ContentCopyIcon>
                                </Tooltip>
                                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackBar}>
                                    <MuiAlert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                        Copied to clipboard!
                                    </MuiAlert>
                                </Snackbar>
                                <p>Step 2: Enter the room code <b>{gameId}</b></p>
                                <p>Step 3: Select "Join Existing Game"</p>
                            </div>      

    
    const createMiniBoards = function(){
        const miniBoards = [];
        for(const key in boards){
            if(key === playerId){
                continue;
            }
            miniBoards.push(
                <Grid2 size={1} key={uuidv4()}>
                    <MiniBoard playerName={boards[key].playerName} selectedSquares={boards[key].selectedSquares}></MiniBoard>
                </Grid2>
            )
        }

        return miniBoards;
    }

    const createWinnerDisplay = function(){
        if(winningPlayerName){
            return (
                <Grid2 size={12}>
                <Box sx={{
                    border: '2px solid',
                    borderColor: theme.palette.divider,
                    borderRadius: "15px",
                    boxShadow: 24,
                    p: 2,
                    backgroundColor: theme.palette.primary.main,
                    display: "block",
                    textAlign: "center",
                    marginBottom: "25px"
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{fontSize: "20px"}}>
                        {winningPlayerName} is the Winner!
                    </Typography>
                </Box></Grid2>)
        } else {
            return <Grid2 size={12}></Grid2>
        }
    }

    createMiniBoards();

    const handleQuitClicked = function(){
        socket.emit('delete-player', {gameId, playerId});
        socket.disconnect();
        const { origin, pathname } = window.location;
        const newUrl = `${origin}${pathname}`;
        window.location.href = newUrl;
    }
    
    return (
        <Grid2 size={{ xs: 12, sm: 12, md: 5 }} style={{textAlign:"left", margin:"auto", marginTop:"30px", paddingLeft: "1vw", paddingRight: "1vw"}}>
            <Grid2 container size={{ xs: 12, sm: 12, md: 5 }}>

            </Grid2>
            <Grid2 container columns={12}>
                {createWinnerDisplay()}

                <Grid2 container spacing={"clamp(8px, 3vw, 15px)"} columns={3} size={12}>
                    {createMiniBoards()}
                </Grid2>

                <Grid2 container size={12}>
                    <GameFeed feed={feed} playerCount={playerCount}></GameFeed>
                </Grid2>
                
                <Grid2 size={12} style={{paddingLeft:"2vw"}}>
                    
                </Grid2>
                <div>
                    {invitePlayers}
                    <div style={{ marginTop: "20px" }}>
                        <Button onClick={handleQuitClicked} style={{color: theme.palette.text.contrast, backgroundColor: theme.palette.error.main, marginRight:"10px", paddingLeft:"20px", paddingRight:"20px"}}>
                            <CallEnd style={{ fontSize: "20px", margin: "0", marginRight: "10px" }}></CallEnd>
                            Quit Game
                        </Button>
                        <ColorThemeButton handleThemeClicked={handleThemeButtonClicked}></ColorThemeButton>
                    </div>
                </div>
            </Grid2>
        </Grid2>
    )
}

export default Panel;
