import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

function Square({text, index, clicked, handleClick, preventHover}) {
    const theme = useTheme();
    const [hovered, setHovered] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    let backgroundColor = clicked ? theme.palette.primary.main : "transparent";
    let borderColor = clicked ? theme.palette.secondary.main : theme.palette.divider;
    if(hovered && index !== 12){
        borderColor = theme.palette.info.dark;
        backgroundColor = clicked ? theme.palette.primary.main : theme.palette.primary.main + "80";
    }

    const onHover = function(){
        if(!preventHover && !hovered){
            setHovered(true);
        }
    }

    const onHoverOut = function(){
        setHovered(false);
    }

    const onSquareClick = function(){
        if(!preventHover){
            handleClick(index);
        }
    }

    const onTouchEnd = function(){
        setHovered(false);
    }

    const style = {
        backgroundColor,
        borderColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 150 + index * 100);
    
        return () => clearTimeout(timer)
      }, [index]);

    return (
        <div className={`Square slide-in ${isVisible ? 'slide-in-active' : ''}`} onClick={onSquareClick} style={style} onMouseEnter={onHover} onMouseLeave={onHoverOut} onTouchEnd={onTouchEnd}>
            <div style={{padding: "1%"}}>
                <span className="prevent-select">{text}</span>
            </div>
        </div>
    );
}

export default Square;
