
import { useState, useEffect } from 'react';
import EmojiBox from './EmojiBox.js';

function EmojiBarrage() {
    const [emojis, setEmojis] = useState([]);

    const onFocus = () => {
        if(document.hidden){
            setEmojis([]);
        } else {
            rebuildEmojis();
        }
    };
    
    useEffect(() => {
        window.addEventListener("visibilitychange", onFocus);

        return () => {
            window.removeEventListener("visibilitychange", onFocus);
        };
    });

    const rebuildEmojis = function(){
        const newEmojis = [];
        for(let i = 0; i < 25; i++){
            newEmojis.push(<EmojiBox key={i}></EmojiBox>);
        }
        setEmojis(newEmojis);
    }
    
    useEffect(() => {
        if(!document.hidden){
            rebuildEmojis();
        } else {
            setEmojis([]);
        }
    }, []);

    return (
        <>
            {emojis}
        </>
    );
}

export default EmojiBarrage;
